import httpService from "@/request"

// 添加产品
export function backGoodsList(params) {
    return httpService({    
        url: `/order/waybill/back/goods/list`,
        method: 'post',
        data: params,
    })
}
//签收
export function goodSign(params) {
    return httpService({    
        url: `/order/waybill/back/goods/sign`,
        method: 'post',
        data: params,
    })
}
//重发列表
export function repeatList(params) {
    return httpService({    
        url: `/order/waybill/back/repeat/list`,
        method: 'post',
        data: params,
    })
}
//退件单列表
export function waybillBack(params) {
    return httpService({    
        url: `/order/waybill/back`,
        method: 'post',
        data: params,
    })  
}
export function waybillInfo(params) {
    return httpService({    
        url: `/order/waybill/info`,
        method: 'post',
        data: params,
    })  
}
///{{customer}}/order/waybill/edit
export function waybillEdit(params) {
    return httpService({    
        url: `/order/waybill/edit`,
        method: 'post',
        data: params,
    })  
}